import { call, put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from "../../../api/apiWrapper";
import { actions } from '../../actions/actionsWrapper';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { eBaiVietReactActionTypeIds, IBaiVietReactLoadStart } from './../../action-types/bai-viet/IBaiVietReactActionTypes';

const appBaiVietReactApi = apiWrapper.appBaiVietReact;

export function* baiVietReactSaga(): any {
    yield takeLatest(eBaiVietReactActionTypeIds.LOAD_START, loadWorker)
}

function* loadWorker(action: IBaiVietReactLoadStart): any {
    const res: IBaseResponeModel = yield call([appBaiVietReactApi, appBaiVietReactApi.SelectAll], action.payload)
    if (res.is_success && res.data) {
        yield put(actions.baiVietReact.loadSuccess(res.data))
    } else {
        yield put(actions.baiVietReact.loadErr(res.message || "Không tải được danh sách"))
    }
}

