import { app_baiviet_view } from "../../../models/response/app-phuhuynh/app_baiviet_view"

export enum eBaiVietViewActionTypeIds {
    LOAD_START = "BAVIET_VIEW_LOAD_START",
    LOAD_SUCCESS = "BAVIET_VIEW_LOAD_SUCCESS",
    LOAD_ERROR = "BAVIET_VIEW_LOAD_ERROR"
}
export interface IBaiVietViewLoadStart {
    type: eBaiVietViewActionTypeIds.LOAD_START,
    payload: number
}
export interface IBaiVietViewLoadSuccess {
    type: eBaiVietViewActionTypeIds.LOAD_SUCCESS,
    payload: app_baiviet_view[]
}
export interface IBaiVietViewLoadError {
    type: eBaiVietViewActionTypeIds.LOAD_ERROR,
    payload: string
}
export type IBaiVietViewActionTypes = IBaiVietViewLoadStart | IBaiVietViewLoadSuccess | IBaiVietViewLoadError