import { IConvertMoetRequest } from '../models/request/diem/IConvertMoetRequest';
import { IDiemHocSinhSelectDiemChuyenDeRequest } from '../models/request/diem/IDiemHocSinhSelectDiemChuyenDeRequest';
import { IDiemHocSinhSelectRequest } from "../models/request/diem/IDiemHocSinhSelectRequest";
import IDiemMappingBaiTapRequest from '../models/request/diem/IDiemMappingBaiTapRequest';
import { IExportDiemRequest } from '../models/request/diem/IExportDiemRequest';
import { IImportDiemHocSinhRequest } from '../models/request/diem/IImportDiemHocSinhRequest';
import { IUpdateDiemHocSinhRequest } from "../models/request/diem/IUpdateDiemHocSinhRequest";
import { IDiemHocSinhCalculateRequest } from './../models/request/diem/IDiemHocSinhCalculateRequest';
import { apiClient } from "./apiClient";

export const API_DIEM_PATH = "diem"

export const diemApi = {
    selectByLop: (request: IDiemHocSinhSelectRequest) => apiClient.post(`${API_DIEM_PATH}/lop-bo-mon/select`, request),
    selectDiemChuyenDeByLop: (request: IDiemHocSinhSelectDiemChuyenDeRequest) => apiClient.post(`${API_DIEM_PATH}/lop-bo-mon/select/diem-lop-chuyen`, request),
    updateDiem: (request: IUpdateDiemHocSinhRequest) => apiClient.put(`${API_DIEM_PATH}`, request),
    calculateDiem: (request: IDiemHocSinhCalculateRequest) => apiClient.post(`${API_DIEM_PATH}/calculate`, request),
    mapping: (request: IDiemMappingBaiTapRequest) => apiClient.post(`${API_DIEM_PATH}/mapping`, request),
    // convertMoet: (request: IConvertMoetRequest) => apiClient.post(`${API_DIEM_PATH}/convert-moet`, request),
    //tạm ẩn convertMoet vì không dùng, dùng luôn endpoit này để sync sang moet
    convertMoet: (request: IConvertMoetRequest) => apiClient.post(`${API_DIEM_PATH}/sync-to-moet`, request),
    ValidateImport: (request: IImportDiemHocSinhRequest) => apiClient.post(`${API_DIEM_PATH}/import/validate`, request),
    Import: (request: IImportDiemHocSinhRequest) => apiClient.post(`${API_DIEM_PATH}/import`, request),
    ValidateImportLop: (request: IImportDiemHocSinhRequest) => apiClient.post(`${API_DIEM_PATH}/import-lop/validate`, request),
    ImportLop: (request: IImportDiemHocSinhRequest) => apiClient.post(`${API_DIEM_PATH}/import-lop`, request),
    updateDiemThanhPhanAll: (request: IUpdateDiemHocSinhRequest) => apiClient.put(`${API_DIEM_PATH}/thanh-phan/update`, request),
    Export: (request: IExportDiemRequest) => apiClient.post(`${API_DIEM_PATH}/export`, request),

} 