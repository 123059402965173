import { app_baiviet_react } from "../../../models/response/app-phuhuynh/app_baiviet_react"

export enum eBaiVietReactActionTypeIds {
    LOAD_START = "BAVIET_REACT_LOAD_START",
    LOAD_SUCCESS = "BAVIET_REACT_LOAD_SUCCESS",
    LOAD_ERROR = "BAVIET_REACT_LOAD_ERROR"
}
export interface IBaiVietReactLoadStart {
    type: eBaiVietReactActionTypeIds.LOAD_START,
    payload: number
}
export interface IBaiVietReactLoadSuccess {
    type: eBaiVietReactActionTypeIds.LOAD_SUCCESS,
    payload: app_baiviet_react[]
}
export interface IBaiVietReactLoadError {
    type: eBaiVietReactActionTypeIds.LOAD_ERROR,
    payload: string
}
export type IBaiVietReactActionTypes = IBaiVietReactLoadStart | IBaiVietReactLoadSuccess | IBaiVietReactLoadError