import { NotifyHelper } from '../../../helpers/toast';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eBaiVietViewActionTypeIds, IBaiVietViewActionTypes } from './../../action-types/bai-viet/IBaiVietViewActionTypes';
import { IBaiVietViewPageState } from './../../page-state-model/bai-viet/IBaiVietViewPageState';

const initialState: IBaiVietViewPageState = {
    status: ePageBaseStatus.is_not_initialization,
    app_baiviet_views: []
}

export const baiVietViewReducer = (state: IBaiVietViewPageState = initialState, action: IBaiVietViewActionTypes): IBaiVietViewPageState => {
    switch (action.type) {
        case eBaiVietViewActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading,
                app_baiviet_views: []
            }
        case eBaiVietViewActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviet_views: action.payload
            }
        case eBaiVietViewActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviet_views: []
            }
        default:
            return state;
    }

}